/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

* {
  margin: 0;
  padding: 0;
  font: inherit;
  font-family: "Pretendard";
  color: inherit;
}

::-webkit-scrollbar {
  width: 16px; /* 가로 사이즈 */
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: #c1c1c0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7d7d7d;
}
